<template>
  <span
    v-if="notes.length"
  >
    <v-divider v-if="dividerTop" />
    <v-card-text
      class="pb-0"
    >
      <v-alert
        v-for="(status, index) in notes"
        :key="index"
        :dark="dark(status.name)"
        dense
        :color="color(status.name)"
      >
        <template #prepend>
          <v-icon
            small
            class="mr-2"
            v-text="icon(status.name)"
          />
        </template>
        {{ status.reason }}
      </v-alert>
    </v-card-text></span>
</template>

<script>
  export default {
    props: {
      statusText: {
        type: [String, Array],
        default: 'needs review',
        require: true,
      },
      statuses: {
        type: Array,
        default: () => ([]),
      },
      type: {
        type: String,
        default: 'flag',
      },
      dividerTop: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      status_props: {
        'needs review': { color: 'error', dark: true, icon: 'fas fa-flag-alt' },
        'tech support': { color: 'yellow darken-2', dark: false, icon: 'fas fa-code' },
        'contact lab': { color: 'blue lighten-1', dark: true, icon: 'fas fa-flask' },
      },
    }),
    computed: {
      notes () {
        if (Array.isArray(this.statusText)) {
          return this.statuses.filter(item => this.statusText.includes(item.name) && item.reason != null && item.reason != 'auto')
        } else {
          return this.statuses.filter(item => item.name === this.statusText && item.reason != null && item.reason != 'auto')
        }
      },
    },
    methods: {
      color (name) {
        return this.type == 'notes' ? 'info' : this.status_props[name].color
      },
      dark (name) {
        return this.type == 'notes' ? true : this.status_props[name].dark
      },
      icon (name) {
        return this.type == 'notes' ? 'fas fa-comment' : this.status_props[name].icon
      },
    },
  }
</script>
